import Image from 'next/image';
import { IProductItem } from '@/types/name';
import TagsList from '@/src/components/elements/TagsList';
import Link from 'next/link';
import ExternalLink from '@/src/components/ui/ExternalLink';
import graphSvg from '@/public/icons/graph.svg';
import { usePathname } from 'next/navigation';

type ProductItemType = Omit<IProductItem, 'id' | 'slug' | 'productId'> & {
    activeTag?: string[];
};

type PathSourceTypes =
    | 'bfn_main'
    | 'bfn_category'
    | 'bfn_serie'
    | 'bfn_brand'
    | 'bfn_shop';

const pathSourceMap: Record<string, PathSourceTypes> = {
    main: 'bfn_main',
    tag: 'bfn_category',
    series: 'bfn_serie',
    brand: 'bfn_brand',
    shop: 'bfn_shop',
};

const ProductItem = ({
    title,
    price,
    oldPrice,
    description,
    tags,
    url,
    prisradarUrl,
    imageUrl,
    activeTag,
    publishedAt,
}: ProductItemType) => {
    const pathname = usePathname();
    const pathnameKey = pathname.length > 1 ? pathname.split('/')[1] : 'main';

    const urlObject = new URL(url);
    urlObject.searchParams.set('source', pathSourceMap[pathnameKey]);

    const urlWithSource = urlObject.toString();

    return (
        <li className="p-15 bg-secondary/30 relative grid grid-cols-[auto_1fr_auto] items-center border border-gray rounded-lg lg:gap-y-20 md:gap-y-12 md:p-12">
            <div className="w-240 h-240 rounded-lg relative overflow-hidden bg-white mr-30 row-start-1 row-span-2 col-span-1 lg:row-span-1 lg:w-200 lg:h-200 md:w-150 md:h-150 sm:mr-10">
                <Image
                    key={imageUrl}
                    src={imageUrl}
                    alt={title}
                    fill
                    sizes="(max-width: 768px) 150px, (max-width: 1024px) 200px, 240px"
                    className="object-contain"
                />
            </div>

            <div className="flex flex-col gap-10 row-start-1 row-span-1 col-start-2 col-span-1 lg:gap-4">
                <div className="text-xl font-semibold text-ellipsis overflow-hidden lg:line-clamp-2 md:text-sm">
                    {title}
                </div>

                <div className="flex gap-30 text-4xl font-bold lg:gap-20 lg:text-3xl md:text-lg">
                    <div className="text-primary">{price},-</div>

                    <div className="line-through text-lightGray">
                        {oldPrice},
                    </div>
                </div>

                <div className="text-base line-clamp-2 text-ellipsis text-extraLightGray overflow-hidden lg:line-clamp-3  md:text-12">
                    {description}
                </div>

                {publishedAt && (
                    <div className="text-16 text-extraLightGray md:text-12">
                        Published:{' '}
                        <span className="font-semibold">{publishedAt}</span>
                    </div>
                )}
            </div>

            <div className="row-start-2 row-span-1 col-start-2 col-span-1 lg:col-start-1 lg:col-span-2 md:row-start-3">
                {tags && <TagsList tags={tags} activeTag={activeTag} />}
            </div>

            <div className="flex flex-col gap-15 ml-80 row-start-1 row-span-2 col-start-3 col-span-1 lg:ml-30 md:flex-row-reverse  md:row-start-2 md:row-span-1 md:col-start-1 md:col-span-2 md:p-15 md:bg-secondary/30 md:ml-0 md:rounded-lg">
                <ExternalLink href={urlWithSource}>
                    <div className="btn btn-primary sm:px-10">
                        Gå til butikk
                    </div>
                </ExternalLink>

                <ExternalLink href={`${prisradarUrl}#price-history`}>
                    <div className="btn btn-secondary md:rounded-full md:min-w-42 md:px-12 md:py-14 md:w-42">
                        <span className="md:hidden">Prishistorie</span>
                        <Image
                            src={graphSvg}
                            alt="price history"
                            className="w-full fill-white hidden md:block"
                        />
                    </div>
                </ExternalLink>

                <Link
                    className="w-full h-100 relative shrink-0 bg-white rounded-lg overflow-hidden md:h-42 md:w-78 md:mr-auto"
                    type="shop"
                    href={`/shop/${tags['shop'].slug}`}
                >
                    <Image
                        src={tags['shop'].imageUrl}
                        alt={`${tags['shop'].title} Black Friday 2024`}
                        fill
                        className="object-contain"
                        sizes="(max-width: 768px) 80px, 183px"
                    />
                </Link>
            </div>
        </li>
    );
};

export default ProductItem;
