'use client';

import { getProducts } from '@/src/app/actions';
import { IProductItem } from '@/types/name';
import ProductItem from '@/src/components/elements/ProductItem';
import { useLoadMore } from '@/src/hooks/useLoadMore';
import LoadMoreButton from '@/src/components/elements/LoadMoreButton';

export interface IProductList {
    initialItems: IProductItem[];
    nextPage?: number;
    tag?: string[];
}

const ProductsList = ({ initialItems, nextPage, tag }: IProductList) => {
    const { items, isLoading, onClickLoad, isNextPageAvailable } = useLoadMore({
        initialItems,
        nextPage,
        request: getProducts,
        tag,
    });

    return (
        <div className="max-w-full">
            <ul className="flex flex-col gap-10">
                {items?.map((item, i) => (
                    <ProductItem
                        key={`${i}_${item.productId}`}
                        title={item.title}
                        price={item.price}
                        oldPrice={item.oldPrice}
                        description={item.description}
                        tags={item.tags}
                        url={item.url}
                        prisradarUrl={item.prisradarUrl}
                        imageUrl={item.imageUrl}
                        discountPercent={item.discountPercent}
                        discountPrice={item.discountPrice}
                        publishedAt={item.publishedAt}
                    />
                ))}
            </ul>
            {isNextPageAvailable && (
                <LoadMoreButton
                    onClickLoad={onClickLoad}
                    isLoading={isLoading}
                />
            )}
        </div>
    );
};

export default ProductsList;
