import { useCallback, useState } from 'react';

interface IUseLoadMoreResult<T> {
    items: T[];
    isLoading: boolean;
    onClickLoad: () => void;
    isNextPageAvailable: boolean;
}

interface ResponseData<T> {
    items: T[];
    nextPage: number;
}

interface ILoadMore<T> {
    initialItems: T[];
    request: ({
        slug,
        searchParams,
    }: {
        slug?: string[];
        searchParams?: Record<string, any>;
    }) => Promise<ResponseData<T>>;
    tag?: string[];
    nextPage?: number;
}

export const useLoadMore = <T>({
    initialItems,
    request,
    tag,
    nextPage,
}: ILoadMore<T>): IUseLoadMoreResult<T> => {
    const [items, setItems] = useState<T[]>(initialItems);
    const [page, setPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isNextPageAvailable, setIsNextPageAvailable] = useState<boolean>(
        !!nextPage,
    );

    const onClickLoad = useCallback(async () => {
        if (!isNextPageAvailable) return;

        setIsLoading(true);

        const { items, nextPage } = await request({
            ...(tag && { slug: tag }),
            ...(page && { searchParams: { page } }),
        });

        setItems(prevState => [...prevState, ...items]);
        setPage(prevState => prevState + 1);
        setIsNextPageAvailable(!!nextPage);
        setIsLoading(false);
    }, [isNextPageAvailable, page, request, tag]);

    return {
        items,
        isLoading,
        onClickLoad,
        isNextPageAvailable,
    };
};
