import { Tags, TagType } from '@/types/name';
import Link from 'next/link';

interface ITagsList {
    tags: Tags;
    activeTag?: string[];
}

const typeMapping: Record<TagType, string> = {
    brand: 'brand',
    category: 'tag',
    shop: 'shop',
    series: 'series',
};

const TagsList = ({ activeTag, tags }: ITagsList) => {
    const [tagSlug, tag] = activeTag || [];
    const { shop, ...renderedTags } = tags;

    const commonStyles =
        'px-15 py-4 h-46 flex items-center rounded-lg whitespace-nowrap md:h-30 md:text-12';

    return (
        <div className="flex gap-15 flex-wrap">
            {Object.entries(renderedTags).map(([tagType, tagInfo]) =>
                tagSlug === typeMapping[tagType as TagType] &&
                tag === tagInfo.slug ? (
                    <span
                        key={tagInfo.id}
                        className={`${commonStyles} bg-secondary/40 text-light`}
                    >
                        {tagInfo.title}
                    </span>
                ) : (
                    <Link
                        key={tagInfo.id}
                        href={`/${typeMapping[tagType as TagType]}/${
                            tagInfo.slug
                        }`}
                        className={`${commonStyles} border border-light bg-secondary/50 text-lightGray`}
                    >
                        {tagInfo.title}
                    </Link>
                ),
            )}
        </div>
    );
};

export default TagsList;
